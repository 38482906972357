import { Skeleton, Stack } from '@mui/material';

export type Props = {
  id: string;
};

export const CellSkeleton = ({ id }: Props) => (
  <Stack data-testid={`Loading Skeleton ${id}`} height="100%" justifyContent="center" width="100%">
    <Skeleton sx={(theme) => ({ height: theme.spacing(4) })} width="100%" />
  </Stack>
);
