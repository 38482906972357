import { Box, Grid, useTheme, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { useIsDeepUpUser } from '../../hooks/useIsDeepUpUser';
import { Spacer } from '../../styles/Spacer';

export type Props = {
  heading: string;
  serialNumberSearch: ReactNode;
  activityStatusFilter: ReactNode;
  deviceTable: ReactNode;
  clientSearch: ReactNode;
  projectSearch: ReactNode;
};

export const Layout = ({
  heading,
  serialNumberSearch,
  activityStatusFilter,
  deviceTable,
  clientSearch,
  projectSearch,
}: Props) => {
  const { spacing, typography } = useTheme();

  const isDeepUpUser = useIsDeepUpUser();

  return (
    <Stack sx={{ width: '100%', flexDirection: 'row' }}>
      <Spacer width={spacing(5)} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography sx={{ fontSize: spacing(4), fontWeight: typography.fontWeightBold }}>
          {heading}
        </Typography>
        <Spacer height={spacing(3)} />
        <Grid container spacing={spacing(2)}>
          <Grid item md={3} sm={3} xs={12}>
            {serialNumberSearch}
          </Grid>
          {isDeepUpUser && (
            <Grid item md={3} sm={3} xs={12}>
              {clientSearch}
            </Grid>
          )}
          <Grid item md={3} sm={3} xs={12}>
            {projectSearch}
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            {activityStatusFilter}
          </Grid>
        </Grid>
        <Spacer height={spacing(3)} />
        {deviceTable}
      </Box>
      <Spacer width={spacing(5)} />
    </Stack>
  );
};
