import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import type { PropsWithChildren } from 'react';

import { useEnvironment } from '../../hooks/useEnvironment';

import { Alert } from './Alert';

export const Auth = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect, logout } = useAuth0();
  const { isDev } = useEnvironment();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && error) {
    return <Alert error={error} logout={logout} />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch(
      (e) => isDev && console.error(e),
    );

    return <LoadingScreen />;
  }

  return children;
};
