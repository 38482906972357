import { SxProps } from '@mui/system';
import type {
  GridColDef,
  GridPaginationModel,
  GridSortItem,
  GridSortDirection,
  GridColumnResizeParams,
} from '@mui/x-data-grid';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { deDE, enUS } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Device } from '../../models/device';

import { CellSkeleton } from './Cells/CellSkeleton';
import { HeaderCell } from './Cells/HeaderCell';
import { HeaderCellWithInfo } from './Cells/HeaderCellWithInfo';
import { NoRowsOverlay } from './NoRowsOverlay';
import { TableRows } from './TableRows';
import { DeviceTableStyle, NotSortable } from './styles';

export type TablePaginationModel = GridPaginationModel;
export type TableSortModel = GridSortItem;
export type TableSortDirection = GridSortDirection;
export const pageSizeOptions = [25, 50, 100];

type ColumnConfig = Pick<GridColDef, 'field' | 'minWidth' | 'width' | 'sortable'> & {
  withInfoIcon?: boolean;
};

const defaultColumnConfigs: ColumnConfig[] = [
  { field: 'serialNumber', minWidth: 150, sortable: true, width: 200 },
  { field: 'clientOrganization', minWidth: 300, sortable: true, width: 300 },
  { field: 'project', minWidth: 300, width: 300, sortable: true, withInfoIcon: true },
  { field: 'address', minWidth: 200, width: 300, sortable: true, withInfoIcon: true },
  { field: 'activityStatus', minWidth: 100, width: 200, sortable: false, withInfoIcon: true },
];

export type Props = {
  devices: Device[];
  deviceCount: number;
  isLoading: boolean;
  paginationModel: TablePaginationModel;
  sortModel: TableSortModel;
  onPaginationModelChange?: (paginationModel: TablePaginationModel) => void;
  onSortModelChange?: (sortModel: TableSortModel) => void;
  filtersActive: boolean;
} & Pick<DataGridProps, 'hideFooterPagination'>;

export const DeviceTable = ({
  devices,
  deviceCount,
  isLoading,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  filtersActive,
  hideFooterPagination,
}: Props) => {
  const { t, i18n } = useTranslation();

  const rows = TableRows(devices);

  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>(defaultColumnConfigs);

  const columns: GridColDef[] = useMemo(
    () =>
      columnConfigs.map(({ field, minWidth, width, withInfoIcon, sortable }) => ({
        field,
        minWidth,
        width,
        sortable,
        renderCell: ({ value, id }) => (isLoading ? <CellSkeleton id={`${field}-${id}`} /> : value),
        renderHeader: ({ field }) => {
          const caption = t(`deviceOverview.deviceTable.columns.${field}.header`);

          if (!withInfoIcon) {
            return <HeaderCell>{caption}</HeaderCell>;
          }

          const info = (
            <Trans
              components={[<br key="br" />]}
              i18nKey={`deviceOverview.deviceTable.columns.${field}.headerInfo`}
            />
          );

          return <HeaderCellWithInfo info={info}>{caption}</HeaderCellWithInfo>;
        },
      })),
    [t, isLoading, columnConfigs],
  );

  const gridTranslations = useMemo(
    () => (i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText,
    [i18n.language],
  );

  const rowSkeletons = useMemo(
    () => [...Array(paginationModel.pageSize).keys()].map((id) => ({ id, serialNumber: '' })),
    [paginationModel.pageSize],
  );

  const handleColumnResize = ({
    colDef: { field, width: newColumnWidth },
  }: GridColumnResizeParams) =>
    setColumnConfigs((currentColumnConfigs) =>
      currentColumnConfigs.map((currentColumnConfig) =>
        currentColumnConfig.field === field
          ? { ...currentColumnConfig, width: newColumnWidth }
          : currentColumnConfig,
      ),
    );

  return (
    <DataGrid
      autoHeight={deviceCount > 0}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      disableVirtualization
      hideFooterPagination={hideFooterPagination}
      loading={isLoading}
      localeText={gridTranslations}
      onColumnResize={handleColumnResize}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={(sortModel) => onSortModelChange?.(sortModel[0])}
      pageSizeOptions={pageSizeOptions}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={deviceCount ?? 0}
      rows={isLoading ? rowSkeletons : rows}
      slots={{
        noRowsOverlay: () => <NoRowsOverlay filtersActive={filtersActive} />,
        loadingOverlay: () => null,
      }}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      sx={
        {
          ...DeviceTableStyle,
          ...(rows.length === 0 ? NotSortable : {}),
        } as SxProps
      }
    />
  );
};
