import { CircleQuestionmarkOutline } from '@deepup/icons';
import { Icon, Stack, Tooltip, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { Spacer } from '../../../styles/Spacer';

export type Props = PropsWithChildren<{
  info: ReactNode;
}>;

export const HeaderCellWithInfo = ({ children, info }: Props) => {
  const theme = useTheme();
  const iconSize = theme.spacing(2.5);

  return (
    <>
      <div>{children}</div>
      <Spacer width={({ spacing }) => spacing(1)} />
      <Tooltip title={info}>
        <Stack alignItems="center" flexDirection="row">
          <Icon sx={{ height: iconSize, width: iconSize }}>
            <CircleQuestionmarkOutline height={iconSize} width={iconSize} />
          </Icon>
        </Stack>
      </Tooltip>
      <Spacer width={({ spacing }) => spacing(1)} />
    </>
  );
};
