import { PropsWithChildren } from 'react';

import { Spacer } from '../../../styles/Spacer';

export const HeaderCell = ({ children }: PropsWithChildren) => (
  <>
    <div>{children}</div>
    <Spacer width={({ spacing }) => spacing(1)} />
  </>
);
