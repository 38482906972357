import type { LogoutOptions } from '@auth0/auth0-react';
import { Alert as MuiAlert, AlertTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CenteredFlex } from '../../styles/CenteredFlex';

export type Props = {
  error: Error;
  logout: (options?: LogoutOptions) => Promise<void>;
};

export const Alert = ({ error, logout }: Props) => {
  const { t } = useTranslation();

  const LogoutButton = () => (
    <Button onClick={() => logout()} variant="text">
      {t('auth.logout')}
    </Button>
  );

  return (
    <MuiAlert action={<LogoutButton />} severity="error" sx={CenteredFlex} variant="outlined">
      <AlertTitle>
        {t('auth.alert.error')}: {error.message}
      </AlertTitle>
      <div>{t('auth.alert.detail')}</div>
    </MuiAlert>
  );
};
