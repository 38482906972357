import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useIsDeepUpUser = (): boolean => {
  const { user } = useAuth0();
  const [isDeepUpUser, setIsDeepUpUser] = useState(false);

  useEffect(() => {
    const userDomain = user?.email?.split('@')[1]?.toLowerCase();

    setIsDeepUpUser(userDomain?.startsWith('deepup') ?? false);
  }, [user]);

  return isDeepUpUser;
};
