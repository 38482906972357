/* v8 ignore start */
import { GlobalHeader as DeepUpGlobalHeader } from '@deepup/global-header';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { SupportedLanguage } from '../../i18n';

export const headerHeightSpacing = 10 as const;
const HeaderSpace = () => <Box sx={(theme) => ({ height: theme.spacing(headerHeightSpacing) })} />;

export const GlobalHeader = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <DeepUpGlobalHeader<SupportedLanguage>
        guideConfig={{ appIdentifier: 'device-management' }}
        language={i18n.language as SupportedLanguage}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showLanguageToggle
        showThemeToggle
        title={t('header.heading')}
      />
      <HeaderSpace />
    </>
  );
};
/* v8 ignore stop */
