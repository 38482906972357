import { useEffect } from 'react';

import { useTracking } from '../../hooks/useTracking';

import { DeviceOverviewContent } from './Content';

export const DeviceOverview = () => {
  const { trackPageView } = useTracking();

  useEffect(() => {
    trackPageView('deviceOverview');
  }, [trackPageView]);

  return <DeviceOverviewContent />;
};
