import * as Sentry from '@sentry/react';
import { type SWRResponse } from 'swr';

export const useErrorTracking = () => {
  const trackError = (error: Error) => {
    Sentry.captureException(error);
  };

  const checkResponse = (response: SWRResponse) => {
    if (response.error) {
      trackError(response.error);
    }
  };

  return {
    trackError,
    checkResponse,
  };
};
