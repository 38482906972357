import { NotFoundError as DeepUpNotFoundError } from '@deepup/error-states';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UUID } from '../../types/uuid';

export type Props = {
  id?: UUID;
};

export const NotFoundError = ({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <DeepUpNotFoundError
        caption={t('error.notFound.caption')}
        details={t('error.notFound.details')}
        id={id ? t('error.id', { errorId: id }) : undefined}
      />
    </Box>
  );
};
