/* v8 ignore start */
import type { Environment } from '../models/environment';

const getBuildEnvironment: () => Environment = () => {
  const {
    DEV,
    MODE,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_API_URL,
    VITE_MIXPANEL_TOKEN,
  } = import.meta.env;

  const STAGING = MODE === 'test';
  const PROD = import.meta.env?.PROD && !STAGING;

  return {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    apiUrl: VITE_API_URL,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: DEV,
    isProd: PROD,
    isStaging: STAGING,
    mixpanelToken: VITE_MIXPANEL_TOKEN,
  };
};
/* v8 ignore stop */

const getRuntimeEnvironment = () => {
  const env = window.__env__ || {};

  const envVars = {
    auth0Audience: env.VITE_AUTH0_AUDIENCE,
    auth0ClientId: env.VITE_AUTH0_CLIENT_ID,
    auth0Domain: env.VITE_AUTH0_DOMAIN,
    enableProductionProfiling: env.VITE_ENABLE_PRODUCTION_PROFILING
      ? env.VITE_ENABLE_PRODUCTION_PROFILING === 'true'
      : undefined,
    apiUrl: env.VITE_API_URL,
    mixpanelToken: env.VITE_MIXPANEL_TOKEN,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const useEnvironment = () => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};
