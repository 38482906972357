import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const i18n = createInstance();
const languageDetector = new LanguageDetector();

const supportedLanguages = ['de', 'en'] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];
const fallbackLanguage: SupportedLanguage = 'en';

import de from './locales/de.json';
import en from './locales/en.json';

i18n.use(languageDetector).init({
  detection: {
    ...languageDetector.i18nOptions,
    convertDetectedLanguage: (language) => {
      if (supportedLanguages.includes(language as SupportedLanguage)) {
        return language;
      }

      return fallbackLanguage;
    },
  },
  fallbackLng: fallbackLanguage,
  load: 'currentOnly',
  resources: { de, en },
});
