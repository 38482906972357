import { SearchOutline, XOutline } from '@deepup/icons';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

const toSearchTermArray = (value: string): string[] =>
  value
    ?.trim()
    .split(' ')
    .filter((el) => el) ?? [];

export type Props = {
  searchTerms: string[];
  label: string;
  placeholder: string;
  onChange: (searchTerms: string[]) => void;
};

export const SearchField = ({
  searchTerms: searchTermsProp,
  onChange,
  label,
  placeholder,
}: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(searchTermsProp.join(' '));

  const debouncedOnChange = useDebouncedCallback(onChange, 500);

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutline />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <IconButton
            aria-label={t('deviceOverview.deviceFilter.clearButton.ariaLabel')}
            onClick={() => {
              setValue('');
              debouncedOnChange([]);
            }}
          >
            <XOutline />
          </IconButton>
        ) : null,
      }}
      label={label}
      onChange={({ target: { value } }) => {
        setValue(value);
        debouncedOnChange(toSearchTermArray(value));
      }}
      placeholder={placeholder}
      sx={{ display: 'flex' }}
      value={value}
    />
  );
};
