import { Address } from './address';
import { Asset } from './asset';
import { Organization } from './organization';

export const activityStatus = ['inUse', 'active', 'inactive'] as const;
export type ActivityStatus = (typeof activityStatus)[number];

export type Device = {
  id: string;
  serialNumber: string;
  clientOrganization: Organization | null;
  latestAsset: Asset | null;
  latestAddress: Address | null;
  activityStatus: ActivityStatus;
};
