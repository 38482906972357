/// <reference types="vite-plugin-svgr/client" />
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyStateDark from '../../assets/emptyStateDark.svg?react';
import EmptyStateLight from '../../assets/emptyStateLight.svg?react';
import { CenteredFlex } from '../../styles/CenteredFlex';
import { Spacer } from '../../styles/Spacer';

export type Props = {
  filtersActive: boolean;
};

export const NoRowsOverlay = ({ filtersActive = false }: Props) => {
  const { t } = useTranslation();
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Box sx={{ ...CenteredFlex, flexDirection: 'column' }}>
      <Spacer height="100px" />
      {mode === 'light' ? <EmptyStateLight /> : <EmptyStateDark />}
      <Spacer sx={({ spacing }) => ({ height: spacing(3) })} />
      <Typography variant="h6">{t('deviceOverview.deviceTable.noRows.caption')}</Typography>
      <Spacer sx={({ spacing }) => ({ height: spacing(1) })} />
      <Typography variant="body1">
        {t(
          `deviceOverview.deviceTable.noRows.${filtersActive ? 'detailsWithFilter' : 'detailsWithoutFilter'}`,
        )}
      </Typography>
    </Box>
  );
};
