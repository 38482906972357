import { UUID } from './uuid';

export class FetchError extends Error {
  status: number;
  errorKey?: string;
  id?: UUID;

  constructor(status: number, errorKey?: string, id?: UUID) {
    super(errorKey);

    this.status = status;
    this.errorKey = errorKey;
    this.id = id;
  }
}
