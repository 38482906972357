import { UnknownError as DeepUpUnknownError } from '@deepup/error-states';
import { Box, Link } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';

import { UUID } from '../../types/uuid';

const RetryLink = ({ children }: PropsWithChildren) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Link href={window.location.href} onClick={() => resetBoundary()}>
      {children}
    </Link>
  );
};

export type Props = {
  id?: UUID;
  errorKey?: string;
};

export const UnknownError = ({ id, errorKey: key }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <DeepUpUnknownError
        caption={t('error.general.caption')}
        details={
          <>
            {key && i18n.exists(`${key}.details`)
              ? t(`${key}.details`)
              : t('error.general.details')}{' '}
            <Trans components={[<RetryLink key={0} />]} i18nKey="error.retry" />
          </>
        }
        id={id ? t('error.id', { errorId: id }) : undefined}
      />
    </Box>
  );
};
