import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Spacer } from '../../../styles/Spacer';
import { TextEllipsis } from '../../../styles/TextEllipsis';

export type Props = PropsWithChildren;

export const Cell = ({ children }: Props) => (
  <Stack alignItems="center" flexDirection="row" height="100%">
    <Box sx={TextEllipsis}>{children}</Box>
    <Spacer width={({ spacing }) => spacing(1)} />
  </Stack>
);
