import { WarningOutline } from '@deepup/icons';
import { Box, Icon, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActivityStatus } from '../../../models/device';
import { Spacer } from '../../../styles/Spacer';

export type Props = {
  activityStatus: ActivityStatus;
};

export const ActivityStatusCell = ({ activityStatus }: Props) => {
  const { t } = useTranslation();

  const { palette, spacing } = useTheme();
  const iconSize = spacing(2.5);

  return (
    <Stack alignItems="center" flexDirection="row" height="100%">
      {activityStatus === 'inactive' ? (
        <Stack alignItems="center" flexDirection="row">
          <Icon
            sx={{ height: iconSize, width: iconSize, display: 'flex', color: palette.error.main }}
          >
            <WarningOutline height={iconSize} width={iconSize} />
          </Icon>
          <Spacer sx={{ width: spacing(1) }} />
        </Stack>
      ) : null}

      <Box
        sx={
          activityStatus === 'inactive' ? { color: palette.error.main, fontWeight: 'bold' } : null
        }
      >
        {t(`deviceOverview.activityStatus.${activityStatus}`)}
      </Box>
    </Stack>
  );
};
