import { SxProps } from '@mui/system';

export const DeviceTableStyle: SxProps = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'auto',
  },
};

export const NotSortable: SxProps = {
  '.MuiDataGrid-columnHeader': {
    cursor: 'auto',
  },
  '.MuiDataGrid-iconButtonContainer': {
    display: 'none',
  },
  '.MuiDataGrid-sortIcon': {
    display: 'none',
  },
};
