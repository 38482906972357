import { MenuItem, Select, Divider, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActivityStatus, activityStatus } from '../../models/device';

export type Props = {
  activityStatus: ActivityStatus | null;
  onChange: (activityStatus: ActivityStatus | null) => void;
};

export const ActivityStatusFilter = ({ activityStatus: activityStatusProp, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id="activity-status-filter">
        {t('deviceOverview.activityStatusFilter.label')}
      </InputLabel>
      <Select
        aria-label={t('deviceOverview.activityStatusFilter.ariaLabel')}
        label={t('deviceOverview.activityStatusFilter.label')}
        labelId="activity-status-filter"
        onChange={({ target: { value } }) =>
          onChange((value === 'all' ? null : value) as ActivityStatus | null)
        }
        sx={{ display: 'flex' }}
        value={activityStatusProp ?? 'all'}
      >
        <MenuItem value={'all'}>{t(`deviceOverview.activityStatus.all`)}</MenuItem>
        <Divider />
        {activityStatus.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`deviceOverview.activityStatus.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
