import './setupErrorTracking';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './main.css';

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(<App />);
