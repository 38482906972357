import { Auth0Provider, useAuth0, type AppState } from '@auth0/auth0-react';
import { ThemePaletteModeContext, ThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Auth } from './components/Auth';
import { GlobalHeader } from './components/GlobalHeader';
import { useEnvironment } from './hooks/useEnvironment';
import { useErrorTracking } from './hooks/useErrorTracking';
import { useTracking } from './hooks/useTracking';
import { i18n } from './i18n';
import { DeviceOverview } from './pages/DeviceOverview';
import { NotFoundError } from './pages/Error/NotFoundError';
import { UnknownError } from './pages/Error/UnknownError';
import { PageContainer } from './styles/PageContainer';

const onRedirectCallback = ({ returnTo }: AppState = {}) => {
  if (!returnTo) return;

  window.history.replaceState({}, '', returnTo);
};

const AppRouting = () => {
  const { user } = useAuth0();
  const { init } = useTracking();
  const { mixpanelToken } = useEnvironment();

  init(mixpanelToken, user);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DeviceOverview />} path="/" />
        <Route element={<NotFoundError />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export const App = () => {
  const { themePaletteModeContextValue, theme } = useDeepUpTheme();
  const { auth0Audience, auth0ClientId, auth0Domain } = useEnvironment();

  const { trackError } = useErrorTracking();

  return (
    <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <Auth0Provider
            authorizationParams={{
              audience: auth0Audience,
              redirect_uri: window.location.origin,
            }}
            clientId={auth0ClientId}
            domain={auth0Domain}
            onRedirectCallback={onRedirectCallback}
          >
            <Auth>
              <>
                <GlobalHeader />
                <PageContainer>
                  <ErrorBoundary
                    fallbackRender={({ error }) => (
                      <UnknownError errorKey={error.key} id={error.id} />
                    )}
                    onError={trackError}
                  >
                    <AppRouting />
                  </ErrorBoundary>
                </PageContainer>
              </>
            </Auth>
          </Auth0Provider>
        </I18nextProvider>
      </ThemeProvider>
    </ThemePaletteModeContext.Provider>
  );
};
