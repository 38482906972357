import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityStatusFilter } from '../../components/ActivityStatusFilter';
import { DeviceTable, TableSortModel } from '../../components/DeviceTable';
import { SearchField } from '../../components/SearchField';
import { useDevices } from '../../hooks/useDevices';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useTracking } from '../../hooks/useTracking';
import { ActivityStatus } from '../../models/device';
import { UnauthorizedError } from '../Error/UnauthorizedError';
import { UnknownError } from '../Error/UnknownError';

import { Layout } from './layout';

export const DeviceOverviewContent = () => {
  const {
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel,
  } = useQueryParams();
  const { data, error, isLoading, isRetrying } = useDevices(
    paginationModel,
    sortModel,
    filterModel,
  );

  const { t } = useTranslation();
  const { track } = useTracking();

  const [deviceCount, setDeviceCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!isLoading && data) setDeviceCount(data?.totalElements);
  }, [data, isLoading]);

  const handleSortModelChange = (sortModel: TableSortModel) => {
    track('deviceOverview.sortOrderChanged', { sortModel });

    setSortModel(sortModel);
  };

  const serialNumberSearch = (
    <SearchField
      label={t('deviceOverview.deviceFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.serialNumber', { searchTerms });

        setFilterModel({ ...filterModel, serialNumber: searchTerms });
      }}
      placeholder={t('deviceOverview.deviceFilter.placeholder')}
      searchTerms={filterModel.serialNumber}
    />
  );

  const clientOrganizationSearch = (
    <SearchField
      label={t('deviceOverview.clientFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.client', { searchTerms });

        setFilterModel({ ...filterModel, client: searchTerms });
      }}
      placeholder={t('deviceOverview.clientFilter.placeholder')}
      searchTerms={filterModel.client}
    />
  );

  const projectSearch = (
    <SearchField
      label={t('deviceOverview.projectFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.project', { searchTerms });

        setFilterModel({ ...filterModel, project: searchTerms });
      }}
      placeholder={t('deviceOverview.projectFilter.placeholder')}
      searchTerms={filterModel.project}
    />
  );

  const activityStatusFilter = (
    <ActivityStatusFilter
      activityStatus={filterModel.activityStatus as ActivityStatus & null}
      onChange={(activityStatus) => {
        track('deviceOverview.filterChanged.activityStatus', { activityStatus });

        setFilterModel({ ...filterModel, activityStatus: activityStatus });
      }}
    />
  );

  const filtersActive = filterModel.serialNumber.length > 0 || filterModel.activityStatus !== null;

  const deviceTable = (
    <DeviceTable
      deviceCount={deviceCount ?? 0}
      devices={data?.content ?? []}
      filtersActive={filtersActive}
      hideFooterPagination={deviceCount === undefined}
      isLoading={isLoading || isRetrying}
      onPaginationModelChange={setPaginationModel}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      sortModel={sortModel}
    />
  );

  if (error?.status === 401) {
    return <UnauthorizedError />;
  }

  if (error && !isRetrying) {
    return <UnknownError errorKey={error.key} id={error.id} />;
  }

  return (
    <Layout
      activityStatusFilter={activityStatusFilter}
      clientSearch={clientOrganizationSearch}
      deviceTable={deviceTable}
      heading={t('deviceOverview.heading')}
      projectSearch={projectSearch}
      serialNumberSearch={serialNumberSearch}
    />
  );
};
